/// <reference lib="esnext" />
/// <reference no-default-lib="true"/>
/// <reference lib="dom" />

import { HARD_RESET, HMR, READY, REGISTER, RESET } from "./events";

declare global {
  interface Window {
    __hmr__: any;
    __PREVIEW__: boolean;
  }
}

let cachedFiles: Record<string, Uint8Array> | null = null;

function sendMessageToSW(sw: ServiceWorker, data: {}): Promise<any> {
  return new Promise((resolve) => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = (event: MessageEvent) => {
      resolve(event.data);
    };
    sw.postMessage(data, [messageChannel.port2]);
  });
}

navigator.serviceWorker
  .register(new URL(`service-worker.ts`, import.meta.url), {
    type: "module",
    scope: "./",
    updateViaCache: "imports",
  })
  .then((sw) => {
    console.log("preview: Service Worker Registered");
    window.parent?.postMessage(
      {
        type: READY,
      },
      "*",
    );
    if (sw.active && cachedFiles) {
      console.log("preview: sending cached files to service worker");
      sendMessageToSW(sw.active!, {
        type: "REGISTER",
        files: cachedFiles,
      }).then(handleSWResponse);
    }
  })
  .catch((err) => {
    console.log("preview: Service Worker Failed to Register", err);
  });

function handleSWResponse(data: any) {
  switch (data.type) {
    case READY:
      console.log("preview: sending ready to editor bc in vanilla mode");
      window.parent?.postMessage(
        {
          type: READY,
        },
        "*",
      );
      break;
    case HMR:
      console.log("got HMR message from vanilla preview");
      window.location.reload();
      break;
    default:
      console.log("preview: vanilla page received unknown message type:", data);
      break;
  }
}

window.addEventListener("message", handleMessage);
async function handleMessage(event: MessageEvent) {
  const data = event.data;
  switch (data.type) {
    case HARD_RESET:
    case RESET:
      console.log("preview: sending reset to service worker");
      window.location.reload();
      break;
    case READY:
      console.log("preview: got ready from editor? I don't think we need this");
      window.location.reload();
      break;
    case REGISTER:
      try {
        if (data.files) {
          if (data.ts) {
            const now = Date.now();
            console.log(`vanilla got register, took ${now - data.ts}ms`);
          }
          const transferables = Object.values(
            data.files as Record<string, Uint8Array>,
          ).filter((file) => file.byteLength !== 0);
          if (transferables.length > 0) {
            const reg = await navigator.serviceWorker.ready;
            const sw = reg.active!;
            if (sw) {
              sendMessageToSW(sw, {
                type: "REGISTER",
                files: data.files,
              }).then(handleSWResponse);
            } else {
              console.log("caching files!", data.files);
              cachedFiles = data.files;
            }
          } else {
            console.log("preview: no files to send to service worker");
          }
        }
      } catch (err) {
        console.log("register error", err);
      }
      break;
    default:
      console.log("preview: page received unknown message type:", data);
      break;
  }
}
