export const PING = "PING";
export const INIT_PORT = "INIT_PORT";
export const REGISTER = "REGISTER";
export const READY = "READY";
export const RESET = "RESET";
export const HARD_RESET = "HARD_RESET";
export const HMR = "HMR";
export const PREVIEW = "PREVIEW";
export const RELOAD = "RELOAD";
export const WORKFLOW = "WORKFLOW";
export const NOTIFY = "NOTIFY";
